// // DEPARTAMENTOS.VUE

<template>
  <v-layout id="departamentos-permisos" v-if="modulo !== null" wrap>
    <v-flex xs12>
      <v-card class="deptos" v-bind="ent_deptos">
        <!-- Cabecera -->
        <v-card-title class="header">
          <!-- Título cabecera -->
          <v-flex xs12>
            <v-icon class="titulo">{{ ent_headerDeptos.icon_header }}</v-icon
            >{{ ent_headerDeptos.titulo }}
          </v-flex>

          <!-- Switch para activar /desactivar módulo -->
          <v-flex xs12>
            <v-switch v-model="moduloActivo" label="Activar módulo"></v-switch>
          </v-flex>
        </v-card-title>

        <!-- Departamentos / Niveles -->
        <v-card-text class="infoPermisos">
          <v-simple-table v-bind="ent_permisoMto">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    @click="onClick_col(index)"
                    v-for="(nivel, index) in niveles"
                    :key="index"
                  >
                    {{ nivel }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  @click="onClick_row(keyDepto)"
                  v-for="(valDepto, keyDepto) in departamentos"
                  :key="valDepto.id"
                >
                  <td>{{ valDepto.nombre }}</td>
                  <td
                    @click="onClick_chip(keyDepto, keyNivel)"
                    v-for="(valNivel, keyNivel) in dn[keyDepto]"
                    :key="keyNivel"
                  >
                    <chipPermisos
                      ref="ref_chipsPermisos"
                      :cfg="ent_chips_permisoMto"
                      :chip_values="valNivel"
                      :clonando="clonando"
                      :children="modulo.children.length"
                      :permisos_customBtns="
                        get_permisos_customBtns(keyDepto, keyNivel)
                      "
                      @update_chipSeleccionado="update_chipSeleccionado"
                      @change_customBtns="change_customBtns"
                    >
                    </chipPermisos>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <!-- Permisos particulares -->
        <v-card-actions class="footer">
          <template class="infoClonacion">
            <clonacion
              ref="ref_clonacion"
              :cfg="ent_clonacion"
              :clonando="clonando"
              :chip_seleccionado="chip_seleccionado"
              @selecciono_todosPermisos="selecciono_todosPermisos"
              @deselecciono_todosPermisos="deselecciono_todosPermisos"
              @fin_clonacion="fin_clonacion"
            >
            </clonacion>
          </template>

          <template class="infoCustomBtns">
            <customBtns
              :cfg="ent_permisoAccion"
              :acciones="customBtns"
              :permisoAccion="permisos2customBtns"
              :clonando="clonando"
            >
            </customBtns>
          </template>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import plugs from "@/common/general_plugs";
const customBtns = () => plugs.groute("customBtns.vue", "comp");
const clonacion = () => plugs.groute("clonacion.vue", "comp");
const chipPermisos = () => plugs.groute("chipPermisos.vue", "comp");

export default {
  components: { customBtns, clonacion, chipPermisos },
  props: ["modulo", "clonando", "cfg"],
  data() {
    return {
      // variables de configuración
      ent_deptos: this.cfg,
      ent_headerDeptos: this.cfg.header,
      ent_permisoMto: this.cfg.permisoMto,
      ent_chips_permisoMto: this.cfg.chips_permisoMto,
      ent_permisoAccion: this.cfg.permisoAccion,
      ent_clonacion: this.cfg.clonacion,

      // variables del componente
      niveles: [
        "Departamento",
        "Básica",
        "Invitado",
        "Nivel 1", // array de niveles de la tabla (columnas)
        "Nivel 2",
        "Nivel 3",
        "Nivel 4",
        "Nivel 5",
        "Nivel 6",
        "Nivel 7",
        "Nivel 8",
        "Nivel 9"
      ],
      departamentos: [], // array de deptos de la tabla (from bd)
      dn: [], // array de permisos del módulo
      dnInicial: [], // copia del array de permisos
      customBtns: [], // array con el nombre de los botones particulares
      permisoAccion: [], // array de permisos de acciones
      permisoAccionInicial: [], // copia del array de permisos de acciones
      permisos2customBtns: null, // permisos que mando al comp customBtns al pulsar el boton 'b' en los chips
      chip_seleccionado: null, // this completo del chip que está seleccionado
      chipsclonacion_seleccionados: [] // array donde se guardan los chips a clonar
    };
  },

  created() {
    this.get_deptos();
  },

  computed: {
    // switch para activar o desactivar el módulo seleccionado
    moduloActivo: {
      get() {
        return this.modulo.activo === "1" ? true : false;
      },

      set(valor) {
        this.modulo.activo = valor ? "1" : "0";
      }
    }
  },

  methods: {
    /*********************************************************************************************************** DATOS */

    // obtengo todos los departamentos definidos en la bd
    async get_deptos() {
      var args = {
        tipo: "",
        ruta: "libs",
        api: "permisos",
        accion: "getDeptos"
      };

      var apiResult= await this.$store.dispatch("ajaxRequest", { args: args });
      console.log('api: ', apiResult);

      this.departamentos= apiResult.r[0];
    },

    // obtengo los permisos de todos los deptos del módulo seleccionado
    get_permisosDeptos() {
      var d = [];
      var n = [];

      this.modulo["dn"].split("").map(item => {
        // paso los permisos de hexadecimal a binario
        let r = this.permisosToBin(item);

        if (r === "0") {
          n.push([false, false, false, false]);
        } else {
          n.push(
            r.split("").map(it => {
              return it === "0" ? false : true;
            })
          );
        }

        if (n.length > 10) {
          d.push(n);
          n = [];
        }
      });

      this.dn = JSON.parse(JSON.stringify(d));
      this.dnInicial = JSON.parse(JSON.stringify(d));
    },

    // obtengo tanto el nombre de los botones particulares del módulo como sus permisos
    get_customBtns() {
      this.customBtns =
        this.modulo.pb === null ? [] : this.modulo.pb.split(",");

      if (this.modulo.un === null || this.modulo.un === "") return;
      let r = this.modulo.un.split("#").map(itDeptos => {
        return itDeptos.split("]").map(itNivels => {
          return itNivels.split("");
        });
      });

      this.permisoAccion = JSON.parse(JSON.stringify(r));
      this.permisoAccionInicial = JSON.parse(JSON.stringify(r));
    },

    /*********************************************************************************************************** EVENTOS */
    // selecciono individualmente los permisos a clonar (Solo modo Clonación)
    onClick_chip(keyDepto, keyNivel) {
      if (!this.clonando) return;

      // si estamos en el paso 1 (selección de permisos a clonar), guardo el permiso selecccionado
      if (this.$refs.ref_clonacion.paso === 1) {
        let pos = keyDepto * (this.niveles.length - 1) + keyNivel;
        this.update_chipSeleccionado(this.$refs.ref_chipsPermisos[pos]);
        return;
      }

      // si no estamos en el paso 3 (selección de permisos a ser clonados) ó no es una selección individual, SALGO
      if (
        this.$refs.ref_clonacion.paso !== 3 ||
        this.$refs.ref_clonacion.clonacion_seleccionada !== 3
      )
        return;

      // true/false. Actualizo selección del permiso pulsado
      let pos = keyDepto * (this.niveles.length - 1) + keyNivel;
      this.$refs.ref_chipsPermisos[pos].seleccionado_clonacion = !this.$refs
        .ref_chipsPermisos[pos].seleccionado_clonacion;

      // guardo el permiso en un array
      if (this.$refs.ref_chipsPermisos[pos].seleccionado_clonacion) {
        this.chipsclonacion_seleccionados[pos] = this.$refs.ref_chipsPermisos[
          pos
        ];
      } else {
        this.chipsclonacion_seleccionados[pos] = null;
      }
    },

    // selecciono todos los permisos de uno o varios departamentos a clonar (Solo modo Clonación)
    onClick_row(index) {
      if (!this.clonando) return;

      // si NO estamos en el paso 3 (selección de permisos a ser clonados) ó el modo de clonación no es por deptos, SALGO
      if (
        this.$refs.ref_clonacion.paso !== 3 ||
        this.$refs.ref_clonacion.clonacion_seleccionada !== 1
      )
        return;

      // recorro todos los permisos de la fila seleccionada y los selecciono/deselecciono
      var nniveles = this.niveles.length - 1;
      for (var x = index * nniveles; x < index * nniveles + nniveles; x++) {
        // true/false. Actualizo selección de los permisos del departamento pulsado
        this.$refs.ref_chipsPermisos[x].seleccionado_clonacion = !this.$refs
          .ref_chipsPermisos[x].seleccionado_clonacion;

        // guardo los permisos en un array
        if (this.$refs.ref_chipsPermisos[x].seleccionado_clonacion) {
          this.chipsclonacion_seleccionados[x] = this.$refs.ref_chipsPermisos[
            x
          ];
        } else {
          this.chipsclonacion_seleccionados[x] = null;
        }
      }
    },

    // selecciono todos los permisos de una o varias columnas a clonar (Solo modo Clonación)
    onClick_col(index) {
      if (!this.clonando) return;

      // si NO estamos en el paso 3 (selección de permisos a ser clonados) ó el modo de clonación no es por niveles, SALGO
      if (
        this.$refs.ref_clonacion.paso !== 3 ||
        this.$refs.ref_clonacion.clonacion_seleccionada !== 2
      )
        return;

      // recorro todos los permisos de la columna seleccionada y los selecciono/deselecciono
      var totalChips = (this.niveles.length - 1) * this.departamentos.length;
      for (
        var x = index - 1;
        x < totalChips;
        x = x + (this.departamentos.length + 1)
      ) {
        // true/false. Actualizo selección de los permisos del nivel pulsado
        this.$refs.ref_chipsPermisos[x].seleccionado_clonacion = !this.$refs
          .ref_chipsPermisos[x].seleccionado_clonacion;

        // guardo los permisos en un array
        if (this.$refs.ref_chipsPermisos[x].seleccionado_clonacion) {
          this.chipsclonacion_seleccionados[x] = this.$refs.ref_chipsPermisos[
            x
          ];
        } else {
          this.chipsclonacion_seleccionados[x] = null;
        }
      }
    },

    // selecciono todos los permisos de los deptos y niveles
    selecciono_todosPermisos() {
      // recorro todos los permisos del módulo
      for (const key in this.$refs.ref_chipsPermisos) {
        // true/false. Actualizo selección de los permisos del nivel pulsado
        this.$refs.ref_chipsPermisos[key].seleccionado_clonacion = true;

        // guardo los permisos en un array
        this.chipsclonacion_seleccionados[key] = this.$refs.ref_chipsPermisos[
          key
        ];
      }
    },

    // deselecciono todos los permisos de los deptos y niveles
    deselecciono_todosPermisos() {
      // recorro todos los permisos del módulo
      for (const key in this.$refs.ref_chipsPermisos) {
        // deselecciono todos los permisos
        this.$refs.ref_chipsPermisos[key].seleccionado_clonacion = false;

        // guardo los permisos en un array
        this.chipsclonacion_seleccionados[key] = null;
      }
    },

    // guardo todos los permisos, tanto los de Mto como las acciones
    async guardar() {
      console.log("guardando...");
      // paso los permisos de Mto de binario a hexadecimal
      var dn = this.permisoMtoToHex();

      // paso las acciones de binario a string
      var un = this.permisoAccionToString();

      // guardo en base de datos
      var args = {
        tipo: "",
        ruta: "libs",
        api: "permisos",
        accion: "savePermisos",
        fn_args: {
          id:this.modulo.id,
          menu: this.modulo.menu,
          activo: this.modulo.activo,
          dn: dn,
          un: un
        }
      };
      console.log(args);
      var result = await this.$store.dispatch("ajaxRequest", { args: args });
      console.log(result);
      if (!result) {
        alert("Error en la grabación");
        return;
      }

      this.dnInicial = JSON.parse(JSON.stringify(this.dn));
      alert("Guardado correctamente");
    },

    // cancelo los cambios que hemos realizado
    cancelar() {
      // pongo los permisos de los botones generales a su valor inicial
      this.dn = JSON.parse(JSON.stringify(this.dnInicial));

      // pongo los permisos de los botones particulares a su valor inicial
      this.permisoAccion = JSON.parse(
        JSON.stringify(this.permisoAccionInicial)
      );

      // borro el chip seleccionado
      this.borrar_chipSeleccionado();

      // pongo a null los permisos que envio a customBtns
      this.permisos2customBtns = null;

      // deselecciono todos los permisos seleccionados para su clonación
      this.deselecciono_todosPermisos();

      // inicializo variables utilizadas para la clonación
      this.inicializar_vars_clonacion();
    },

    // clonación de permisos
    clonar() {
      // actualizo la variable que indica si estamos en modo clonación
      this.$emit("update_clonando", !this.clonando);

      //this.chip_pulsado= '';
      this.inicializar_vars_clonacion();
    },

    /* FUNCIONES VARIAS */

    // paso el permiso recibido de hexadecimal a binario
    permisosToBin(num) {
      let bin = parseInt(num, 16).toString(2);
      let binZero = "";

      for (let x = 0; x < 4 - bin.length; x++) {
        binZero += "0";
      }
      return binZero + bin;
    },

    // paso los permisos de Mto de binario a hexadecimal
    permisoMtoToHex() {
      return this.dn
        .map(itD => {
          return itD
            .map(itN => {
              return parseInt(
                itN
                  .map(item => {
                    return item === false ? "0" : "1";
                  })
                  .join(""),
                2
              )
                .toString(16)
                .toUpperCase();
            })
            .join("");
        })
        .join("");
    },

    // paso las acciones de binario a string
    permisoAccionToString() {
      return this.permisoAccion
        .map(itD => {
          return itD
            .map(itN => {
              return itN
                .map(item => {
                  return item;
                })
                .join("");
            })
            .join("]");
        })
        .join("#");
    },

    // actualizo los permisos de los chips seleccionados
    fin_clonacion(accion) {
      if (accion === "guardar") {
        // guardo los permisos de todos los departamentos/niveles
        for (const key in this.chipsclonacion_seleccionados) {
          // si el chip a clonar está a nulo, paso al siguiente
          if (this.chipsclonacion_seleccionados[key] === null) continue;

          // descompongo la key en key del depto y key del nivel
          let keyDepto = Math.trunc(key / (this.niveles.length - 1));
          let keyNivel = key % (this.departamentos.length + 1);

          // actualizo los permisos del chip
          for (let x = 0; x < this.chip_seleccionado.chip_values.length; x++) {
            this.dn[keyDepto][keyNivel].splice(
              x,
              1,
              this.chip_seleccionado.chip_values[x]
            );
          }

          // actualizo los permisos de los botones particulares
          if (this.chip_seleccionado.permisos_customBtns) {
            for (
              let x = 0;
              x < this.chip_seleccionado.permisos_customBtns.length;
              x++
            ) {
              this.permisoAccion[keyDepto][keyNivel].splice(
                x,
                1,
                this.chip_seleccionado.permisos_customBtns[x]
              );
            }
          }
        }
      }

      // deselecciono todos los permisos seleccionados para su clonación
      this.deselecciono_todosPermisos();

      // cambio el estado de la variable que indica que estamos clonando
      this.$emit("update_clonando", !this.clonando);

      // inicializo variables utilizadas para la clonacion
      this.inicializar_vars_clonacion();
    },

    // inicializamos las variables asociacas a la clonación
    inicializar_vars_clonacion() {
      this.borrar_chipSeleccionado();
      this.chipsclonacion_seleccionados = [];
    },

    // actualizo la variable de permisos seleccionados
    update_chipSeleccionado(_this) {
      // deselecciono el chip anteriormente seleccionado
      this.borrar_chipSeleccionado();

      // guardo el nuevo chip seleccionado
      _this.seleccionado = !_this.seleccionado;
      this.chip_seleccionado = _this;
    },

    // si hay algún permisos seleccionado, lo pongo a false
    borrar_chipSeleccionado() {
      if (this.chip_seleccionado) this.chip_seleccionado.seleccionado = false;
    },

    //
    change_customBtns(permisos) {
      this.permisos2customBtns = permisos;
    },

    // devuelvo null si no tenemos botones particulares, si no, devuelvo su valor
    get_permisos_customBtns(keyDepto, keyNivel) {
      if (this.permisoAccion.length > 0)
        return this.permisoAccion[keyDepto][keyNivel];
      return null;
    }
  },

  watch: {
    modulo() {
      this.get_permisosDeptos();
      this.get_customBtns();
    }
  }
};
</script>

<style scope>
.wrapper-departamentos {
  margin-top: 30px;
  margin-left: 35px;
}

.header-departamentos {
  margin: 0 auto;
  padding-left: 5px;
  color: #00492c;
  font-size: 16px;
  padding: 5px;
}

.infoPermisos table {
  margin-top: 15px;
  font-size: 14px;
  color: #323a3a9e;
}

.infoPermisos thead th:nth-child(1) {
  padding-right: 40px;
}
.infoPermisos tbody span i {
  font-size: 18px;
}
.footer {
  margin-top: 20px;
}
.footer .infoClonacion {
  margin-left: 140px;
}
</style>
